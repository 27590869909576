import { useState, useEffect } from "react";
import { Col, Row, Alert } from "react-bootstrap";

export const Newsletter = ({ status, message, onValidated }) => {
  return (
    <Col lg={12}>
      <div className="newsletter-bx wow slideInUp">
        <Row>
          <Col lg={12} >
            <h3>Machine Learning Enthusiast & Data Science Enthusiast</h3>
          </Col>
        </Row>
      </div>
    </Col>
  );
};
