import { Col } from "react-bootstrap";

export const ProjectCard = ({ title, description, imgUrl, projectLink }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} />
        <div className="proj-txtx">
          <h4>{title}</h4>
          <span>{description}</span>
          <br />
          <h1>
            <a
              href={projectLink}
              style={{ color: "black", textDecoration: "none" }}
              target="_blank"
            >
              Click Here!!
            </a>
          </h1>
        </div>
      </div>
    </Col>
  );
};
